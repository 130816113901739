import React from "react";
import s from "./ArticleDownload.module.css";
import GooglePlay from "../../common/assets/Google-Play.png";
import AppStore from "../../common/assets/AppStore.png";
import Rustore from "../../common/assets/Rustore.png";
import AppGallery from "../../common/assets/AppGallery.png";
import KEYS from "../../common/assets/BGj8zfMNIDE.jpg";

const ArticleDownload = () =>{
return(
    <div className={s.mainContainer}>

            <div className={s.containerImgDownload}>
            <a className={s.linkdownload} href="https://play.google.com/store/apps/details?id=com.smd.ip_smd&hl=ru&gl=US" target={"_blank"}>
                    <img className={`${s.imgDownload}`} src={GooglePlay} alt="Google Play" />
                </a>
                <a className={s.linkdownload} href="https://apps.apple.com/ru/app/d-keys/id1502365079" target={"_blank"}>
                    <img className={`${s.imgDownload}`} src={AppStore} alt="App Store" />
                </a>
                <a className={s.linkdownload} href="https://appgallery.huawei.com/app/C106402815" target={"_blank"}>
                    <img className={`${s.imgDownload}`} src={AppGallery} alt="App Store" />
                </a>
                <a className={s.linkdownload} href="https://www.rustore.ru/catalog/app/com.smd.ip_smd" target={"_blank"}>
                    <img className={`${s.imgDownload}`} src={Rustore} alt="App Store" />
                </a>
            </div>
          
    </div>
)
}
export default ArticleDownload;
import React from "react"
import error404 from "../assets/404.png";
import s from "./NotFound.module.css"

const NotFound = () => {
    return (
        <div className={s.container}>
            <img className={s.error404} src={error404} />
            <h3>Страница не найдена...</h3>
            <h3>Проверьте ссылку</h3>            
        </div>
    )
}

export default NotFound;

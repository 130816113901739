import React from "react";
import { compose } from 'redux';
import { connect } from 'react-redux';
import OpenLink from "./OpenLink";
import { OpenLinkCreator, setLinkCreator, setFullLinkCreator, OpenDoorCreator, updateLoading, setFlagOpen, updateLoadingPage } from "../../../redux/openlink-reducer";
import { useEffect } from "react";

const OpenLinkContainer = ({ openlink, OpenLinkCreator, setLinkCreator, setFullLinkCreator, OpenDoorCreator, updateLoading, setFlagOpen, updateLoadingPage}) => {


    useEffect(() => {
        const linkhash = window.location.search.slice(1);
        setLinkCreator(linkhash);
    }, [setLinkCreator]);

    useEffect(() => {
        if (openlink.link) {
            updateLoadingPage();
            // setTimeout(() => {
                            OpenLinkCreator(openlink.link);
            //}, 2000);

        }
    }, [openlink.link, OpenLinkCreator]);


    useEffect(() => {
        if (openlink.fullLink) {
            OpenDoorByLink();
        }
    }, [openlink.fullLink]);

    const setFullLink = (value) => {
        setFullLinkCreator(value);
    };

    const OpenDoorByLink = () => {
        //console.log(openlink);
        if (openlink.fullLink) {
            updateLoading();
            OpenDoorCreator(openlink.fullLink);
            //console.log(openlink.fullLink);
        } else {
            console.log("проблема");
        }
    };

    const FlagOpen = () =>{
        setFlagOpen();
    }

    return <OpenLink
        state={{ openlink }}
        setFullLink={setFullLink}
        FlagOpen={FlagOpen}
    //OpenDoorByLink={OpenDoorByLink}
    />
}
const mapStateToProps = (state) => ({ openlink: state.openlink });

const dispatch = { OpenLinkCreator, setLinkCreator, setFullLinkCreator, OpenDoorCreator, updateLoading, setFlagOpen, updateLoadingPage };

export default compose(
    connect(mapStateToProps, dispatch)
)(OpenLinkContainer);
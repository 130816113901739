import React from "react";
import Navbar from "../Navbar/Navbar";
import s from "./Header.module.css";
import header from "../common/assets/header.png";
import headerMob from "../common/assets/headerMob.png";


const Header = () => {
    return (
        <div className={s.header}>
          <img src={header} className={s.headerImg}></img>
        </div>
    )
}
export default Header
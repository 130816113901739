import { openLink } from "../api/api";

const ACTION = {
    OPEN_LINK: 'OPEN-LINK',
    SET_LINK: 'SET-LINK',
    OPEN_DOOR: 'OPEN-DOOR',
    SET_FULL_LINK: 'SET-FULL-LINK',
    UPDATE_IS_LOADING: 'UPDATE-IS-LOADING',
    FLAG_OPEN: 'FLAG-OPEN',
    LOADING_PAGE: 'LOADING-PAGE'
}
const init = {
    link: '',
    response: [],
    fullLink: '',
    responseOpenDoor: [],
    isLoading: false,
    flag: false,
    loadindPage:false
}

const getOpenLink = (data) => ({ type: ACTION.OPEN_LINK, data });
const setLink = (link) => ({ type: ACTION.SET_LINK, link });
const getOpenDoor = (data) => ({ type: ACTION.OPEN_DOOR, data });
const setFullLink = (fullLink) => ({ type: ACTION.SET_FULL_LINK, fullLink });
const updateIsLoading = () => ({ type: ACTION.UPDATE_IS_LOADING });
const getFlagOpen = () => ({ type: ACTION.FLAG_OPEN });
const setLoadingPageCreator = () =>({type:ACTION.LOADING_PAGE});

const OpenLinkReduser = (state = init, action) => {
    switch (action.type) {
        case ACTION.SET_LINK:
            return {
                ...state,
                link: action.link
            }

        case ACTION.OPEN_LINK:
            return {
                ...state,
                link: '',
                response: action.data,
                loadindPage: false
            }

        case ACTION.SET_FULL_LINK:
            return {
                ...state,
                fullLink: action.fullLink
            }

        case ACTION.OPEN_DOOR:
            return {
                ...state,
                fullLink: '',
                responseOpenDoor: action.data,
                isLoading: false,
                flag: true
            }

        case ACTION.UPDATE_IS_LOADING:
            return {
                ...state,
                isLoading: true
            }

        case ACTION.FLAG_OPEN:
            return {
                ...state,
                flag: false
            }
        case ACTION.LOADING_PAGE:
            return{
                ...state,
                loadindPage: true
            }
        default: return state
    }

}

export const setLinkCreator = (link) => (dispatch) => {
    dispatch(setLink(link))
}

export const OpenLinkCreator = (link) => (dispatch) => {
    openLink.getOpenLinkData(link)
        .then(data => {
            dispatch(getOpenLink(data));
        })
        .catch(err => { console.log(err) });
}


export const setFullLinkCreator = (fullLink) => (dispatch) => {
    //console.log(fullLink)
    dispatch(setFullLink(fullLink))
}

export const OpenDoorCreator = (fullLink) => (dispatch) => {
    openLink.openDoor(fullLink)
        .then(data => {
            dispatch(getOpenDoor(data));
        })
        .catch(err => { console.log(err) })
}

export const updateLoading = () => (dispatch) => {
    dispatch(updateIsLoading());
}

export const setFlagOpen = () => (dispatch) => {
    dispatch(getFlagOpen());
}

export const updateLoadingPage = () => (dispatch) => {
    dispatch(setLoadingPageCreator());
}
export default OpenLinkReduser
import React from 'react';
import s from './Loading.module.css';
import loading from '../assets/loading.gif';

const Loading = () => {
    return (
        <img className={s.loading} src={loading} alt="loading" />
    );
};

export default Loading;
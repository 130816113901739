import { applyMiddleware, combineReducers, createStore } from 'redux';
import {thunk} from 'redux-thunk';
import ArticleCodeMPReduser from './articlecodemp-reducer';
import OpenLinkReduser from './openlink-reducer';
const reducer = combineReducers({
    openlink: OpenLinkReduser,
    articleCodeMP: ArticleCodeMPReduser
});

const store = createStore(reducer, applyMiddleware(thunk));

window.store = store;

export default store;
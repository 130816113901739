import React from "react";
import s from './ChooseCountry.module.css'
// import GooglePlay from "../../../assets/Google-Play.png";
// import AppStore from "../../../assets/AppStore.png";
import download from "../../common/assets/download.png";

const ChooseCountry = () => {
    return (
        <div className={s.mainContainer}>
            <div className={s.containerInfo}>
                <div className={s.header}><span >Choose Your</span> <span className={` ${s.spanForditail}`}> Country or Region</span></div>
                <ul className={s.listCountry}>
                    <li><a className={s.linkCountry} href="*">Armenia</a></li>
                    <li><a className={s.linkCountry} href="*">Brazil</a></li>
                    <li><a className={s.linkCountry} href="https://bg.d-keys.info/">Bulgaria</a></li>
                    <li><a className={s.linkCountry} href="*">Egypt</a></li>
                    <li><a className={s.linkCountry} href="*">Georgia</a></li>
                    <li><a className={s.linkCountry} href="*">India</a></li>
                    <li><a className={s.linkCountry} href="https://kz.d-keys.info/">Kazakhstan</a></li>
                    <li><a className={s.linkCountry} href="*">Moldova</a></li>
                    <li><a className={s.linkCountry} href="*">Mongolia</a></li>
                    <li><a className={s.linkCountry} href="https://ro.d-keys.info/">Romania</a></li>
                    <li><a className={s.linkCountry} href="https://ru.d-keys.info/">Russia</a></li>
                    <li><a className={s.linkCountry} href="*">Turkey</a></li>
                    <li><a className={s.linkCountry} href="https://us.d-keys.info/">USA</a></li>
                    <li><a className={s.linkCountry} href="*">Uzbekistan</a></li>
                </ul>
            </div>
            <div className={s.containerImgDownload}>
                <a className={s.linkfordowload} href="/app"> Download app
                    <img className={s.imgDownload} src={download} alt="download app" />
                </a>
                
                {/* <a href="/SilencingPage" target={"_blank"}>
                    <img className={`${s.imgDownload} ${s.appStorestyle}`} src={AppStore} alt="App Store" />
                </a> */}
            </div>
        </div>
    )
}
export default ChooseCountry;
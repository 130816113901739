import { articleCodeMP } from "../api/api"

const ACTION = {
    GET_ARTICLE: 'GET-ARTICLE',
    SET_CODEMP: "SET-CODEMP",
    LODING_PAGE: "LOADING_PAGE"
}

const init = {
    response: [],
    codeMP: '',
    loading: false
}

const getArticleCreator = (data) => ({ type: ACTION.GET_ARTICLE, data })
const setCodeMPCreator = (codeMP) => ({ type: ACTION.SET_CODEMP, codeMP })
const setloadingPageCreator = () => ({type: ACTION.LODING_PAGE})

const ArticleCodeMPReduser = (state = init, action) => {
    switch (action.type) {
        case ACTION.SET_CODEMP:
            return {
                ...state,
                codeMP: action.codeMP
            }

        case ACTION.GET_ARTICLE:
            return {
                ...state,
                codeMP: "",
                response: action.data,
                loading: false
            }
        case ACTION.LODING_PAGE:
            return{
                ...state,
                loading: true

            }

        default: return state
    }
}

export const setCodeMP = (codeMP) => (dispatch) => {
    dispatch(setCodeMPCreator(codeMP))
}
export const getArticleCodeMP = (codeMP) => (dispatch) => {
    articleCodeMP.getArticle(codeMP)
        .then(data => {
            dispatch(getArticleCreator(data))
        })
        .catch(err => { console.error(err) })
}
export const setLoadingPage = () => (dispatch) => {
    dispatch(setloadingPageCreator());
}

export default ArticleCodeMPReduser
import React, { useState } from "react";
import s from "./Navbar.module.css";
import logo from "../common/assets/logo.svg"
import minlogo from "../common/assets/logo-mob-1.png"
import { Link } from "react-scroll";
import { HashLink } from 'react-router-hash-link';
import { useNavigate } from "react-router-dom";

const Navbar = () =>{
    const [Menu, setMenu]  = useState(false);
    const navigate = useNavigate();

    const handleScrollTo = (id) => {
        navigate('/');  // Переход на главную страницу

        setTimeout(() => {
            const element = document.getElementById(id);  // Поиск элемента по ID
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });  // Прокрутка до элемента
            }
        }, 0);  // Таймаут для того, чтобы убедиться, что рендер завершен
    };

return (
    <>
    <div className={s.navbarContainer}>
       {/* <img className={s.imgLogo} src={logo}/>
         <nav className={s.navMenu}>
            <ul className={s.ulMenu}>
                <li><a  onClick={() => handleScrollTo('carouselinfo')} smooth={true} duration={900} offset={300} className={s.linkMenu}>Услуги</a></li>
                <li><a  onClick={() => handleScrollTo('accordioninfo')} smooth={true} duration={900} offset={10} className={s.linkMenu}>Кейсы</a></li>
                <li><a  onClick={() => handleScrollTo('contact')} smooth={true} duration={900} offset={300} className={s.linkMenu}>Контакты</a></li>
                <li> <a   className={s.linkphone} href="tel:+79605400020">+7 (960) 540-00-20</a></li>
            </ul>
        </nav> */}
        {/* <div>
            <button className={s.menubutton} onClick={()=>{setMenu(true)}}>
                <IoMenu fontSize={50} color="white"/>
            </button>
        </div> */}
    </div>
    {/* {
        Menu ?
        <div className={s.backMenu}>
          <nav className={s.minnavMenu}>
            <button className={`${s.menubutton} ${s.buttonclose}`} onClick={()=>{setMenu(false)}}>
            <IoClose fontSize={23}/>
            </button>
            <img className={s.nimimgLogo} src={minlogo}/>
            <ul className={s.minulMenu}>
                <li><Link to="carouselinfo" smooth={true} duration={900} className={s.minlinkMenu}>Услуги</Link></li>
                <li><Link to="accordioninfo" smooth={true} duration={900} offset={300} className={s.minlinkMenu}>Кейсы</Link></li>
                <li><Link to="contact" smooth={true} duration={900} offset={300}  className={s.minlinkMenu}>Контакты</Link></li>
                <li> <Link  className={s.minlinkphone} href="tel:+79605400020">+7 (960) 540-00-20</Link></li>
            </ul>
        </nav> </div>:undefined
    } */}
   
    </>
)
}
export default Navbar
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import ArticleCodeMP from "./ArticleCodeMP";
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getArticleCodeMP, setCodeMP, setLoadingPage } from "../../../redux/articlecodemp-reducer";


const ArticleCodeMPContainer = ({ articleCodeMP, setCodeMP, getArticleCodeMP, setLoadingPage }) => {
    const id = useParams();

    useEffect(() => {
        if(id){
         setCodeMP(id)
        }        
    }, [setCodeMP])

    useEffect(() => {
        if (articleCodeMP.codeMP) {
            setLoadingPage();
            getArticleCodeMP(articleCodeMP.codeMP)
        }
    }, [articleCodeMP.codeMP, getArticleCodeMP])

    return <ArticleCodeMP
        state={{ articleCodeMP }}
    />
}
const mapStateToProps = (state) => ({ articleCodeMP: state.articleCodeMP });

const dispatch = { getArticleCodeMP, setCodeMP, setLoadingPage };

export default compose(connect(mapStateToProps, dispatch))(ArticleCodeMPContainer);
import React from 'react';
import App from './App';
import { compose } from 'redux';
import { connect } from 'react-redux';

class AppComponent extends React.Component {
    render() {
        return <App state={this.props} />
    };
};

const mapStateToProps = (state) => ({auth: state.auth});
const dispatch = {};

export default compose(
    connect(mapStateToProps, dispatch)
)(AppComponent);